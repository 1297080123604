import { Icon, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { Assets } from "../../assets"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import { SyllabusData } from "../data"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './swiper.css';
import React from "react"

export const Syllabus = () => {
    const sliderRef: any = React.useRef(null);

    const handlePrev = React.useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = React.useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <div style={{ width: '100%', position: 'relative', ...CENTER, backgroundColor: Colors.primary }}>
            <Stack direction={'column'} spacing={3} width={isMobile ? '90%' : '60%'} alignItems={'flex-start'} padding={'30px 0'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <img src={Assets.icons.book} style={{ width: 40, height: 40, objectFit: 'contain' }} alt="" />
                    <Typography variant="h6" fontWeight={'400'} color={'#e7e82b'}>Silabus Informatika</Typography>
                </Stack>
                <Stack direction={isMobile ? 'column' : 'row'} width={'100%'} alignItems={'center'} spacing={1} justifyContent={'space-between'}>
                    <Typography width={isMobile ? '100%' : '50%'} variant="h5" fontWeight={'600'} color="#fff">Komitmen Terhadap Pembelajaran Berlanjutan</Typography>
                    {
                        isMobile ? null :
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <IconButton onClick={handlePrev} sx={{ backgroundColor: '#fff', padding: 0.5 }}>
                                    <Icon fontSize={'medium'} sx={{ color: '#000' }}>chevron_left</Icon>
                                </IconButton>
                                <IconButton onClick={handleNext} sx={{ backgroundColor: '#fff', padding: 0.5 }}>
                                    <Icon fontSize={'medium'} sx={{ color: '#000' }}>chevron_right</Icon>
                                </IconButton>
                            </Stack>
                    }
                </Stack>
                <div style={{ width: '100%', position: 'relative' }}>
                    <Swiper
                        ref={sliderRef}
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                            renderBullet: function (index: number, className: any) {
                                return '<span class="' + className + '"></span>';
                            },
                        }}
                        modules={[Pagination]}
                        style={{ width: '100%' }}
                    >
                        {
                            SyllabusData.map((item, index) => (
                                <SwiperSlide key={index} style={{ width: '100%', height: 'auto', backgroundColor: '#fff', borderRadius: 15, paddingBottom: 30 }}>
                                    <Typography variant="h6" fontWeight={'400'} color={'#000'} sx={{ paddingLeft: 3, paddingTop: 1 }}>Semester {item.semester}</Typography>
                                    <TableContainer>
                                        <Table sx={{ width: '100%' }}>
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        isMobile ? null :
                                                            < TableCell sx={{ width: '10%', fontWeight: '700' }} align="center">No</TableCell>
                                                    }
                                                    <TableCell sx={{ width: isMobile ? '50%' : '20%', fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Kode Mata Kuliah</TableCell>
                                                    <TableCell sx={{ width: isMobile ? '50%' : '40%', fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Mata Kuliah</TableCell>
                                                    {
                                                        isMobile ? null :
                                                            <TableCell sx={{ width: '10%', fontWeight: '700', whiteSpace: 'nowrap' }} align="center">SKS</TableCell>
                                                    }
                                                    {
                                                        isMobile ? null :
                                                            <TableCell sx={{ width: '20%', fontWeight: '700', whiteSpace: 'nowrap' }} align="left">Syarat</TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.data.map((item, idx) => (
                                                    <TableRow
                                                        key={idx}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        {
                                                            isMobile ? null :
                                                                <TableCell align="center">{idx + 1}</TableCell>
                                                        }
                                                        <TableCell align="left">{item.id}</TableCell>
                                                        <TableCell align="left">{item.subject}</TableCell>
                                                        {
                                                            isMobile ? null :
                                                                <TableCell align="center">
                                                                    <span style={{
                                                                        color: item.credits <= 2 ? Colors.success : Colors.error,
                                                                        borderRadius: 5,
                                                                        border: `1px solid ${item.credits <= 2 ? Colors.success : Colors.error}`,
                                                                        padding: '0 10px'
                                                                    }}>
                                                                        {item.credits}
                                                                    </span>
                                                                </TableCell>
                                                        }
                                                        {
                                                            isMobile ? null :
                                                                <TableCell align="left">{item.condition}</TableCell>
                                                        }
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </Stack >
        </div >
    )
}