export const Colors = {
  primary: "#204090",
  secondary: "#232222",
  secondaryDark: "#707070",
  success: "#60d195",
  error: "#FF0060",
  warning: "#f49918",
  inherit: "#ffe4e3",
  info: "#0068ff",
};
