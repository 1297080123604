import { Icon, Stack, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { Assets } from "../../assets"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import { Navs } from "../data"

export const Footer = () => {
    const handleClick = (href: string) => {
        window.location.href = href
    }

    return (
        <div style={{ width: '100%', position: 'relative', ...CENTER, backgroundColor: Colors.primary }}>
            <Stack direction={'column'} width={isMobile ? '90%' : '85%'} paddingY={3} paddingTop={5} spacing={3}>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 3 : 0} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <Stack direction={'column'} spacing={3} width={isMobile ? '100%' : "30%"}>
                        <Stack direction={"row"} alignItems={'center'} spacing={3}>
                            <img src={Assets.icons.logo} style={{ height: 50, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="" className="" />
                            <Stack direction={"column"}>
                                <Typography variant="h6" color="#fff" fontWeight={'600'}>UNIVERSITAS</Typography>
                                <Typography variant="body1" color="#fff">SATYA WIYATA MANDALA</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={'center'} spacing={1}>
                            <Icon fontSize={'medium'} sx={{ color: '#fff' }}>place</Icon>
                            <Typography variant="body2" color="#ffffffDD" fontWeight={'400'}>Jl. Sutamsu, Kalibobo, Distrik Nabire, Kabupaten Nabire, Papua 98818</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={'center'} spacing={1}>
                            <Icon fontSize={'medium'} sx={{ color: '#fff' }}>phone</Icon>
                            <Typography variant="body2" color="#ffffffDD" fontWeight={'400'}>+6282047242221</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={'center'} spacing={1}>
                            <Icon fontSize={'medium'} sx={{ color: '#fff' }}>email</Icon>
                            <Typography variant="body2" color="#ffffffDD" fontWeight={'400'}>info@uswim.ac.id</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={'column'} spacing={isMobile ? 2 : 3} width={isMobile ? '100%' : "20%"}>
                        <Typography variant="h6" color="#fff" fontWeight={'600'}>Layanan</Typography>
                        {
                            Navs.map((item, index) => (
                                <Typography
                                    key={index}
                                    variant="body2"
                                    color="#ffffffDD"
                                    fontWeight={'400'}
                                    onClick={() => handleClick(item.navigate)}
                                    style={{ cursor: 'pointer' }}
                                >{item.label}</Typography>
                            ))
                        }
                    </Stack>
                    <Stack direction={'column'} spacing={3} width={isMobile ? '100%' : "20%"}>
                        <Typography variant="h6" color="#fff" fontWeight={'600'}>Sosial Media</Typography>
                        <Stack direction={'row'} spacing={3} alignItems={'center'}>
                            <img src={Assets.icons.instagram} style={{ width: 25, height: 25, objectFit: 'contain' }} alt="" />
                            <img src={Assets.icons.linkedin} style={{ width: 25, height: 25, objectFit: 'contain' }} alt="" />
                            <img src={Assets.icons.x} style={{ width: 25, height: 25, objectFit: 'contain' }} alt="" />
                            <img src={Assets.icons.youtube} style={{ width: 25, height: 25, objectFit: 'contain' }} alt="" />
                        </Stack>
                    </Stack>
                </Stack>
                <div style={{ width: '100%', height: 1, backgroundColor: '#ffffff80' }}></div>
                <Stack direction={isMobile ? 'column' : 'row'} alignItems={'center'} width={'100%'} justifyContent={'space-between'} spacing={isMobile ? 1 : 0}>
                    <Stack direction={'row'} alignItems={'center'} width={isMobile ? '100%' : '50%'} justifyContent={'space-around'}>
                        <Typography variant={isMobile ? "caption" : "body2"} color="#ffffffDD" fontWeight={'400'}>Ketentuan Layanan</Typography>
                        <Typography variant={isMobile ? "caption" : "body2"} color="#ffffffDD" fontWeight={'400'}>Kebijakan Privasi</Typography>
                        <Typography variant={isMobile ? "caption" : "body2"} color="#ffffffDD" fontWeight={'400'}>Keamanan</Typography>
                    </Stack>
                    <Typography width={isMobile ? '100%' : '50%'} variant={isMobile ? "caption" : "body2"} color="#ffffffDD" fontWeight={'400'} textAlign={isMobile ? 'center' : 'right'}>
                        Hak Cipta ©️ 2024 Universitas Satya Wiyata Mandala
                    </Typography>
                </Stack>
            </Stack>
        </div>
    )
}