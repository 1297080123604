import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { Assets } from "../../assets"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import styles from '../styles.module.css'

export const Activity = () => {
    return (
        <div style={{ width: '100%', position: 'relative', ...CENTER }}>
            <Stack direction={'column'} spacing={3} width={isMobile ? '90%' : '85%'} alignItems={'center'} padding={'30px 0'}>
                <Typography variant={isMobile ? 'h5' : "h4"} fontWeight={'700'} textAlign={'center'} width={isMobile ? '100%' : '50%'} color={'#000'}>Jalani Kehidupan Aktivitas Akademik dan Penelitian</Typography>
                <Typography variant="h6" textAlign={'center'} color={'#ababab'} fontWeight={'400'}>Menciptakan lingkungan akademis yang inspiratif dan dinamis</Typography>
                <Stack direction={isMobile ? 'column' : 'row'} alignItems={'stretch'} height={'100vh'} gap={3} width={isMobile ? '100%' : '85%'}>
                    <Stack direction={"column"} height={'100%'} gap={3} width={isMobile ? '100%' : '70%'}>
                        <div style={{ height: '60%', width: '100%', position: 'relative', display: 'inline-block', overflow: 'hidden', borderRadius: 10 }}>
                            <img src={Assets.images.docImg4} className={styles.img} alt="" />
                            <div style={{ backgroundColor: '#00000080', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: '10%', paddingTop: '20%', borderRadius: 10, zIndex: 0 }}>
                                <Typography width={isMobile ? '100%' : '70%'} variant={isMobile ? "h6" : "h4"} fontWeight={'600'} color="#fff">Dinamika Proses Belajar Pembelajaran Berbasis Proyek</Typography>
                                <Typography width={isMobile ? '100%' : '70%'} variant={isMobile ? "body1" : "h6"} fontWeight={'400'} color="#fff">Mendorong mahasiswa untuk terlibat dalam pembelajaran berbasis proyek</Typography>
                            </div>
                        </div>
                        <Stack direction={'row'} style={{ height: '40%' }} gap={3}>
                            <div style={{ height: '100%', width: '50%', display: 'inline-block', overflow: 'hidden', borderRadius: 10 }}>
                                <img src={Assets.images.docImg5} className={styles.img} alt="" />
                            </div>
                            <div style={{ height: '100%', width: '50%', display: 'inline-block', overflow: 'hidden', borderRadius: 10 }}>
                                <img src={Assets.images.docImg2} className={styles.img} alt="" />
                            </div>
                        </Stack>
                    </Stack>
                    <Stack direction={"column"} height={'100%'} gap={3} width={isMobile ? '100%' : '30%'}>
                        <div style={{ height: '40%', display: 'inline-block', overflow: 'hidden', borderRadius: 10 }}>
                            <img src={Assets.images.docImg1} className={styles.img} alt="" />
                        </div>
                        <div style={{ height: '60%', display: 'inline-block', overflow: 'hidden', borderRadius: 10 }}>
                            <img src={Assets.images.docImg3} className={styles.img} alt="" />
                        </div>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}