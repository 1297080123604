import { Button, Icon, Stack, Typography } from "@mui/material"
import { isMobile } from "react-device-detect"
import { Assets } from "../../assets"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import { FacultyData } from "../data"
import styles from '../styles.module.css'

export const History = () => {
    return (
        <div style={{ width: '100%', position: 'relative' }}>
            <Stack direction={'column'} spacing={5} width={'100%'} alignItems={'center'}>
                <Stack direction={isMobile ? "column-reverse" : "row"} spacing={isMobile ? 3 : 0} width={isMobile ? '90%' : '85%'} alignItems={'stretch'} justifyContent={"space-between"}>
                    <div style={{ padding: '10px 0', backgroundColor: '#fff', borderRadius: 20, boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.30)', width: isMobile ? '100%' : '48%' }}>
                        <img src={Assets.images.structure} style={{ width: '100%', height: 'auto', objectFit: 'contain' }} alt="" />
                    </div>
                    <Stack direction={'column'} spacing={3} width={isMobile ? '100%' : "48%"} alignItems={'flex-start'}>
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <img src={Assets.icons.bachelor} style={{ width: 30, height: 30, objectFit: 'contain' }} alt="" />
                            <Typography variant="h6" color={Colors.error}>Tentang Kampus</Typography>
                        </Stack>
                        <Typography variant="h6" color={'#000'}><b>Sejarah Singkat Fakultas Teknologi dan Rekayasa</b></Typography>
                        <Typography variant="body1" color={'#000'} whiteSpace={'pre-line'}>
                            {`Fakultas Teknologi dan Rekayasa Universitas Satya Wiyata Mandala Nabire beralamat di Jl.Sutamsu, SH. Kalibobo Nabire, SK Mendiknas adalah 143/D/O/2004, 06 semptember 2001 dengan pejabat yang menerbitkan SK Satryo Soemantri Brodjonegoro.\n
                        Saat ini, program studi teknik industri dan Program Teknik Informatika sudah mendapatkan akreditasi, Kedua program studi sudah meluluskan mahasiswa dengan gelar sarjana teknik (ST) dan sudah banyak bekerja dibeberapa perusahaan dan menjadi Pegawai Negeri Sipil (PNS)
                        `}
                        </Typography>
                    </Stack>
                </Stack>
                <div style={{ width: '85%' }}>
                    {
                        FacultyData.map((item, index) => (
                            <div key={index} style={{ width: '100%', backgroundImage: `url(${item.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                <Stack direction={'column'} spacing={3} width={'100%'} alignItems={"flex-start"} sx={{ padding: isMobile ? 3 : 5, backgroundColor: '#000000AA', backdropFilter: 'blur(3px)' }}>
                                    <Typography variant="h5" color="#fff" paddingBottom={1} borderBottom={`3px solid #e7e82b`}><b>{item.faculty}</b></Typography>
                                    <Stack direction={isMobile ? "column" : "row"} alignItems='flex-start' width={'100%'} justifyContent={"space-between"}>
                                        <Stack direction={'column'} spacing={1} width={isMobile ? '100%' : '48%'}>
                                            <Typography variant="h6" color="#fff"><b>Visi</b></Typography>
                                            <Typography variant="body1" color="#fff">{item.vision}</Typography>
                                        </Stack>
                                        <Stack direction={'column'} spacing={1} width={isMobile ? '100%' : '48%'}>
                                            <Typography variant="h6" color="#fff"><b>Misi</b></Typography>
                                            <ol>
                                                {
                                                    item.missions.map((item, index) => (
                                                        <li key={index} style={{ marginBottom: 10, color: '#fff' }}>
                                                            <Typography variant="body1" color="#fff">{item}</Typography>
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </div>
                        ))
                    }
                </div>
            </Stack>
        </div >
    )
}