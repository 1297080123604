import { Stack } from '@mui/material';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { Activity } from '../../components/home/activity';
import { Header } from '../../components/home/header';
import { History } from '../../components/home/history';
import { Professors } from '../../components/home/professors';
import { Syllabus } from '../../components/home/syllabus';
import { Footer } from '../../components/navigation/footer';
import NavigationBar from '../../components/navigation/navigationBar';
import RouterTransitions from '../../routes/routerTransitions';

const HomePage = () => {
    return (
        <main style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
            <NavigationBar />
            <Stack direction={'column'} alignItems={'center'} spacing={5} width={'100%'}>
                <Header />
                <div id={"about"}></div>
                <History />
                <div id={"academy"}></div>
                <Syllabus />
                <div id={"professor"}></div>
                <Professors />
                <div id={"gallery"}></div>
                <Activity />
                <Footer />
            </Stack>
        </main>
    )
}

export default RouterTransitions(HomePage);