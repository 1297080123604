import * as React from "react";
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Icon,
    CssBaseline,
    Drawer,
    IconButton,
    Button,
    Typography,
    useScrollTrigger,
} from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { CENTER } from "../../utils/styles";
import { Assets } from "../../assets";
import { Navs } from "../data";
import { Colors } from "../../utils/colors";
import { useNavigate } from "react-router";

const NavigationBar = () => {
    const navigate = useNavigate()
    const [isDrawer, setDrawer] = React.useState(false);

    const handleClick = (href: string) => {
        setDrawer(false)
        window.location.href = href
    }

    const toggleDrawer = () => {
        setDrawer(!isDrawer)
    };

    const onScroll = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return (
        <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="fixed"
                sx={{
                    backgroundColor: onScroll ? Colors.primary : 'transparent',
                    backdropFilter: 'blur(5px)',
                    width: '100%',
                    top: 0,
                    transition: 'all .3s',
                    zIndex: 5,
                    boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.30)'
                }}
            >
                <Toolbar sx={{ ...CENTER }}>
                    {
                        isTablet && isMobile ?
                            <Stack spacing={2} width={'100%'}>
                                <Stack
                                    width={'100%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <img src={Assets.icons.logo} onClick={() => navigate('/')} style={{ height: 50, width: 'auto', objectFit: 'contain' }} alt="logo" />
                                    <Stack direction="row" alignItems={'center'} gap={1} >
                                        <IconButton onClick={() => setDrawer(true)}>
                                            <Icon fontSize={'medium'} sx={{ color: '#fff' }}>menu</Icon>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Stack>
                            :
                            isMobile ?
                                <Stack spacing={2} width={'100%'}>
                                    <Stack
                                        width={'100%'}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                    >
                                        {/* <img src={Assets.icons.logo} onClick={() => navigate('/')} style={{ height: 50, width: 'auto', objectFit: 'contain' }} alt="logo" /> */}
                                        <Stack direction={"row"} alignItems={'center'} spacing={1}>
                                            <img src={Assets.icons.logo} onClick={() => navigate('/')} style={{ height: 50, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="" className="" />
                                            <Stack direction={"column"}>
                                                <Typography variant="body1" color="#fff" fontWeight={'600'}>UNIVERSITAS</Typography>
                                                <Typography variant="body2" color="#fff">SATYA WIYATA MANDALA</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" alignItems={'center'} gap={1}>
                                            <IconButton onClick={() => setDrawer(true)}>
                                                <Icon fontSize={'medium'} sx={{ color: '#fff' }}>menu</Icon>
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                :
                                <div style={{ width: '100%', ...CENTER }}>
                                    <Stack
                                        width={'85%'}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        sx={{ padding: '10px 0' }}
                                    >
                                        <Stack direction={"row"} alignItems={'center'} spacing={3}>
                                            <img src={Assets.icons.logo} onClick={() => navigate('/')} style={{ height: 50, width: 'auto', objectFit: 'contain', cursor: 'pointer' }} alt="" className="" />
                                            <Stack direction={"column"}>
                                                <Typography variant="h5" color="#fff" fontWeight={'600'}>UNIVERSITAS</Typography>
                                                <Typography variant="body1" color="#fff">SATYA WIYATA MANDALA</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={'row'} gap={isTablet ? 0 : 2} alignItems={'center'} sx={{ color: '#fff' }}>
                                            {
                                                Navs.map((item, index) => (
                                                    <Button
                                                        variant="text"
                                                        color="inherit"
                                                        sx={{ textTransform: 'none' }}
                                                        key={index}
                                                        onClick={() => handleClick(item.navigate)}
                                                    >
                                                        <Typography variant="body1" fontWeight={'400'} color={'#fff'}>{item.label}</Typography>
                                                    </Button>
                                                ))
                                            }
                                        </Stack>
                                    </Stack>
                                </div>
                    }
                </Toolbar>
            </AppBar>
            {
                (isTablet && isMobile) || isMobile ?
                    <Drawer
                        sx={{
                            width: '100%',
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: '100%',
                                boxSizing: "border-box",
                            },
                        }}
                        variant={"temporary"}
                        anchor="right"
                        open={isDrawer}
                        onClose={toggleDrawer}
                    >
                        <Stack sx={{ backgroundColor: Colors.primary, height: 'inherit' }} direction={'column'} gap={3} padding={2}>
                            <Stack direction={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                <img src={Assets.icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain' }} alt="" />
                                <IconButton onClick={() => setDrawer(false)}>
                                    <Icon fontSize={'large'} sx={{ color: '#fff' }}>close</Icon>
                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} gap={1} sx={{ color: '#fff' }}>
                                {
                                    Navs.map((item, index) => (
                                        // <Link key={index} onClick={() => handleClick(item.navigate)}>
                                        <Button variant="text" color="inherit" key={index} onClick={() => handleClick(item.navigate)}>
                                            <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={2}>
                                                <Typography variant={'button'} color={"#fff"}>{item.label}</Typography>
                                            </Stack>
                                        </Button>
                                        // </Link>
                                    ))
                                }
                            </Stack>
                        </Stack>
                    </Drawer>
                    :
                    null
            }
        </Box >
    );
};

export default NavigationBar