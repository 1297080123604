import { Assets } from "../assets";
import { Colors } from "../utils/colors";

export const Navs = [
  { id: 1, label: "Tentang Kami", navigate: "#about" },
  { id: 3, label: "Akademik", navigate: "#academy" },
  { id: 2, label: "Dosen", navigate: "#professor" },
  { id: 4, label: "Galeri", navigate: "#gallery" },
];

export const FacultyData = [
  {
    image: Assets.images.facultyImg1,
    faculty: "Teknik Informatika",
    vision:
      "Menjadikan program studi Teknik Informatika yang unggul dan terdepan dalam bidang IT (Informasi Teknologi) serta mengembangkan ilmu pengetahuan dan teknologi yang mendukung pengembangan sumber daya manusia di tanah papua.",
    missions: [
      "Menyelenggarakan pendidikan penelitian dan pengabdian masyarakat yang selaras dengan falsafah perguruan tinggi",
      "Meningkatkan kualitas dan kuantitas civitas akademik program studi informatika dalam rangka mengembangkan data manusia dalam mendukung pembangunan daerah yang berkearifan local",
      "Menyelenggarakan penelitian yang berorientasi pada pembangunan keilmuan Teknik Informatika",
      "Menyiapkan tenaga kerja yang siap pakai yang menguasai iptek sesuai pengembangan tuntutan dunia kerja",
      "Menyampaikan lulusan yang siap bersaing dalam dunia kerja",
    ],
  },
  {
    image: Assets.images.facultyImg2,
    faculty: "Teknik Industri",
    vision:
      "Menjadi program studi yang unggul dan terdepan berstandar nasional yang bermoral Pancasila, beriman dan bertaqwa kepada tuhan yang maha esa, memiliki semangat kerakyatan dan kemandirian dalam pembangunan ilmu pengetahuan dan teknologi yang mendukung pembangunan nasional memajukan masyarakan serta budaya bangsa.",
    missions: [
      "Menyelenggarakan Pendidikan penelitian dan pengabdian manyarakat yang selaras dengan falsafah perguruan tinggi",
      "Meningkatkan kualitas dan kuanttitas civitias akademik program studi Teknik industry dalam tangka mengembangkan sumber daya alam yang mendukung pembangunan nasional",
      "Menyiapkan tenaga kerja yang menguasai IPTEK sesuai perkembangan tuntutan dunia kerja",
      "Meniyapkan lulusan yang siap bersaing dalam dunia kerja",
    ],
  },
];

export const HeaderData = [
  {
    title: "Universitas Satya Wiyata Mandala",
    desc: "Menawarkan akses ke berbagai peluang akademik. Sebagai pemimpin dunia dalam pendidikan tinggi, Universitas telah memelopori perubahan di sektor ini.",
  },
  {
    title: "Pribadi Unggul Berdaya Saing",
    desc: "Pendidikan bukan hanya tentang ilmu pengetahuan, tetapi juga tentang membentuk karakter dan keterampilan yang akan mendukung kesuksesan di masa depan",
  },
  {
    title: "Keseimbangan Spiritual dan Akademik",
    desc: "Berkomitmen untuk menciptakan lingkungan yang inklusif dan saling menghormati dan membina karakter mahasiswa berdasarkan nilai-nilai spiritual",
  },
];

export const SyllabusData = [
  {
    semester: "I",
    data: [
      {
        id: "TIF MKK 1101",
        subject: "Pendidikan Pancasila",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MPK 1102",
        subject: "Pendidikan Agama",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKK 2101",
        subject: "Fisika I",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MPK 2102",
        subject: "Bahasa Inggris I",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKK 1101",
        subject: "Kalkulus I",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MPK 2101",
        subject: "Bahasa Indonesia I",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKK 2103",
        subject: "Pengantar Sistem Komputer",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKK 1104",
        subject: "Aplikasi Komputer I",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKK 1105",
        subject: "Algoritma dan Pemrograman I",
        credits: 3,
        condition: "",
      },
    ],
  },
  {
    semester: "II",
    data: [
      {
        id: "TIF MKK 2202",
        subject: "Fisika II",
        credits: 3,
        condition: "TIF MKK 2101",
      },
      {
        id: "TIF MPK 2203",
        subject: "Bahasa Inggris II",
        credits: 2,
        condition: "TIF MPK 2102",
      },
      {
        id: "TIF MKK 1202",
        subject: "Kalkulus I",
        credits: 3,
        condition: "TIF MKK 1101",
      },
      {
        id: "TIF MPK 1203",
        subject: "Kewarganegaraan",
        credits: 2,
        condition: "TIF MPK 1101",
      },
      {
        id: "TIF MBB 2204",
        subject: "Manajemen & Organisasi",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MPK 2204",
        subject: "Etnografi Papua",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKK 1206",
        subject: "Algoritma dan Pemrograman II",
        credits: 3,
        condition: "TIF MKK 1105",
      },
      {
        id: "TIF MKK 1207",
        subject: "Aplikasi Komputer II",
        credits: 3,
        condition: "TIF MKK 1104",
      },
      {
        id: "TIF MKK 1208",
        subject: "Statistik dan Probabilitas",
        credits: 3,
        condition: "",
      },
    ],
  },
  {
    semester: "III",
    data: [
      {
        id: "TIF MKK 2304",
        subject: "Teknik Digital",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MPK 1303",
        subject: "Matematika Diskrit",
        credits: 3,
        condition: "TIF MKK 1202",
      },
      {
        id: "TIF MKK 2305",
        subject: "Manajemen Proyek",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKB 1301",
        subject: "Struktur Data",
        credits: 3,
        condition: "TIF MKK 1105",
      },
      {
        id: "TIF MKB 2301",
        subject: "Komputer & Internet",
        credits: 3,
        condition: "TIF MKK 2103",
      },
      {
        id: "TIF MPK 2205",
        subject: "Ilmu Sosial & Budaya Dasar",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKB 1302",
        subject: "Sistem Berkas",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKB 1303",
        subject: "Komunikasi Data",
        credits: 3,
        condition: "TIF MKK 2103",
      },
    ],
  },
  {
    semester: "IV",
    data: [
      {
        id: "TIF MKK 2417",
        subject: "Arsitektur & Organisasi Komputer",
        credits: 3,
        condition: "TIF MKK 2103",
      },
      {
        id: "TIF MKB 1403",
        subject: "Jaringan Komputer",
        credits: 3,
        condition: "TIF MKB 1303",
      },
      {
        id: "TIF MKK 1409",
        subject: "Aljabar Linear",
        credits: 3,
        condition: "TIF MKK 1303",
      },
      {
        id: "TIF MKK 2407",
        subject: "Multimedia",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKB 2402",
        subject: "Sistem Informasi",
        credits: 3,
        condition: "TIF MKK 2103",
      },
      {
        id: "TIF MKB 1404",
        subject: "Sistem Operasi",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKB 1404",
        subject: "Sistem Basis Data",
        credits: 3,
        condition: "TIF MKB 1301, 1302",
      },
    ],
  },
  {
    semester: "V",
    data: [
      {
        id: "TIF MKB 2503",
        subject: "Sistem Informasi Manajemen",
        credits: 3,
        condition: "TIF MKB 2402",
      },
      {
        id: "TIF MKB 1506",
        subject: "Pemrograman Berbasis Objek",
        credits: 3,
        condition: "TIF MKB 1301",
      },
      {
        id: "TIF MKK 1510",
        subject: "Metode Numerik",
        credits: 3,
        condition: "TIF MKK 1303",
      },
      {
        id: "TIF MKB 1507",
        subject: "Interaksi Manusia dan Komputer",
        credits: 3,
        condition: "TIF MKB 2301",
      },
      {
        id: "TIF MKK 2508",
        subject: "Kompiler",
        credits: 3,
        condition: "TIF MKB 1301",
      },
      {
        id: "TIF MBB 2504",
        subject: "Kewirausahaan",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKB 1508",
        subject: "Metodologi Penelitian",
        credits: 3,
        condition: "",
      },
    ],
  },
  {
    semester: "VI",
    data: [
      {
        id: "TIF MKB 1709",
        subject: "Rekayasa Perangkat Lunak",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKK 2609",
        subject: "Riset Operasional",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKB 1710",
        subject: "Pemrograman Visual",
        credits: 3,
        condition: "TIF MKB 1507",
      },
      {
        id: "TIF MKK 2610",
        subject: "Web Design I",
        credits: 3,
        condition: "TIF MKB 2301",
      },
      {
        id: "TIF MKK 2611",
        subject: "Mikroprosesor",
        credits: 3,
        condition: "TIF MKK 2304",
      },
      {
        id: "TIF MBB 2612",
        subject: "Keamanan Komputer",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKK 2613",
        subject: "Desain Grafis",
        credits: 3,
        condition: "",
      },
    ],
  },
  {
    semester: "VII",
    data: [
      {
        id: "TIF MPB 1701",
        subject: "Etika Profesi",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MKK 2714",
        subject: "Kecerdasan Buatan",
        credits: 3,
        condition: "",
      },
      {
        id: "TIF MKK 2715",
        subject: "Web Design II",
        credits: 3,
        condition: "TIF MKK 2611",
      },
      {
        id: "TIF MBB 2701",
        subject: "KKN",
        credits: 3,
        condition: "Jumlah SKS > 120",
      },
    ],
  },
  {
    semester: "VIII",
    data: [
      {
        id: "TIF MKB 1801",
        subject: "Seminar",
        credits: 2,
        condition: "",
      },
      {
        id: "TIF MBB 2801",
        subject: "Tugas Akhir",
        credits: 6,
        condition: "Lulus Semua MK",
      },
    ],
  },
];

export const ProfessorsData = [
  { NID: "1219076701", name: "Hermanus. J. Suripatty", position: "Lektor", color: Colors.success },
  { NID: "1231037301", name: "Nicodemus Rahanra", position: "Lektor", color: Colors.error },
  { NID: "1423078601", name: "Rooy. M. Thaniket", position: "Tenaga Pengajar", color: Colors.info },
  { NID: "1420127801", name: "Edwin. A. W. Sanadi", position: "Asisten Ahli", color: Colors.warning },
  { NID: "1412077801", name: "CH. Paul Raymond", position: "Tenaga Pengajar", color: Colors.success },
  { NID: "1424089002", name: "Syukriyanto Latif", position: "Asisten Ahli", color: Colors.error },
  { NID: "1216108001", name: "Ignatius Endar. N", position: "Asisten Ahli", color: Colors.info },
  { NID: "1209068102", name: "Suryadi", position: "Lektor", color: Colors.warning },
  { NID: "8869680018", name: "Ricky Flying Yeninar", position: "Tenaga Pengajar", color: Colors.success },
  { NID: "1223127802", name: "Arfan Natan. P", position: "Asisten Ahli", color: Colors.error },
  { NID: "1207077702", name: "Martinus Tekege", position: "Lektor", color: Colors.info },
  { NID: "1208036701", name: "Marten .D. Palobo", position: "Asisten Ahli", color: Colors.warning },
  { NID: "1215086801", name: "Yusuf Sirampun Pirade", position: "Asisten Ahli", color: Colors.success },
  { NID: "1422127501", name: "Hendra Pasu Parningotan Simanjuntak", position: "Asisten Ahli", color: Colors.error },
  { NID: "1212048301", name: "Emeliana Butu", position: "Tenaga Pengajar", color: Colors.info },
  { NID: "1207067501", name: "Yance Iji", position: "Asisten Ahli", color: Colors.warning },
  { NID: "1226018102", name: "Herman Kendy Pantandianan ", position: "Tenaga Pengajar", color: Colors.success },
  { NID: "PROSES NIDK", name: "Saverius Petege", position: "Tenaga Pengajar", color: Colors.error },
  { NID: "1219128201", name: "Debby.S. Bogar ", position: "Lektor", color: Colors.info },
  { NID: "9914011959", name: "Hendrico.C. Hamel", position: "Tenaga Pengajar", color: Colors.warning },
  { NID: "1220086701", name: "Suardiman Dayadi", position: "Asisten Ahli", color: Colors.success },
  { NID: "1215047001", name: "Everly.D.A Titaley", position: "Asisten Ahli", color: Colors.error },
  { NID: "1420018101", name: "Hendrik Natobtiga", position: "Asisten Ahli", color: Colors.info },
  { NID: "1416087102", name: "Jasmari", position: "Tenaga Pengajar", color: Colors.warning },
  { NID: "1222107902", name: "Petrus Tekege", position: "Lektor", color: Colors.success },
  { NID: "99084199896", name: "Abdi Bustam", position: "Tenaga Pengajar", color: Colors.error },
  { NID: "1202068001", name: "Korneles Anguarmas", position: "Asisten Ahli", color: Colors.info },
  { NID: "PROSES NIDK", name: "Petrus Sadi", position: "Tenaga Pengajar", color: Colors.warning },
  { NID: "PROSES NIDK", name: "Sarce Mala Rangkoly", position: "Tenaga Pengajar", color: Colors.success },
  { NID: "PROSES NIDN", name: "Piterson Djamilgo", position: "Tenaga Pengajar", color: Colors.error },
  { NID: "PROSES NIDN", name: "Maikel Fainsinem", position: "Tenaga Pengajar", color: Colors.info },
  { NID: "PROSES NIDN", name: "Watrisno", position: "Tenaga Pengajar", color: Colors.warning },
  { NID: "PROSES NIDN", name: "Musa Henri Yanto Rahanra", position: "Tenaga Pengajar", color: Colors.success },
];
