import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import HomePage from "../pages/home";
import { AnimatePresence } from "framer-motion";

const PageRoutes = () => {
    const location = useLocation()
    const { pathname } = useLocation();

    React.useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)
    }, [pathname]);

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route index element={<HomePage />} />
            </Routes>
        </AnimatePresence>
    )
}

export default PageRoutes;