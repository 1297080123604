export const Images = {
    headerBg: require('./header-bg.png'),
    profile: require('./profile.png'),
    structure: require('./structures.png'),
    docImg1: require('./doc-1.jpg'),
    docImg2: require('./doc-2.jpg'),
    docImg3: require('./doc-3.jpg'),
    docImg4: require('./doc-4.jpg'),
    docImg5: require('./doc-5.png'),
    facultyImg1: require('./faculty-1.jpg'),
    facultyImg2: require('./faculty-2.jpg'),
}