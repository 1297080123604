import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Icon, Slide, Stack, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import React from "react"
import { isMobile } from "react-device-detect"
import { Assets } from "../../assets"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import { ProfessorsData } from "../data"
import styles from '../styles.module.css'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Lists = (props: { isOpen: boolean; onClose: any }) => {
    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                onClose={props.onClose}
                keepMounted
                disableScrollLock
                scroll="paper"
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        backgroundColor: '#fff',
                        width: '100%',
                        maxWidth: isMobile ? '100vw' : '70vw',
                        borderRadius: 3,
                        border: `1px solid ${Colors.primary}`,
                    }
                }}
            >
                <DialogContent>
                    <Grid container spacing={isMobile ? 1 : 3}>
                        {
                            ProfessorsData.map((item, index) => (
                                <Grid item md={3} xs={6} sm={6} key={index}>
                                    <Card sx={{ width: '100%', height: '100%' }}>
                                        <div style={{ backgroundColor: item.color, width: '100%', height: 100, position: 'relative', ...CENTER }}>
                                            <img
                                                src={Assets.images.profile}
                                                style={{
                                                    width: 'auto',
                                                    height: '80%',
                                                    aspectRatio: 1,
                                                    borderRadius: 100,
                                                }} alt=""
                                            />
                                        </div>
                                        <CardContent>
                                            <Stack direction={"column"} alignItems={'center'} spacing={1}>
                                                <Typography textAlign={'center'} variant={"body2"} color="#ababab">{item.NID}</Typography>
                                                <Typography textAlign={'center'} variant={"body1"} color="#000">{item.name}</Typography>
                                                <Typography textAlign={'center'} variant={"body2"} sx={{ padding: 1, border: '1px solid #ababab', borderRadius: 3 }}>{item.position}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={props.onClose}>Tutup</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export const Professors = () => {
    const [isDetail, setDetail] = React.useState(false)

    return (
        <div style={{ width: '100%', position: 'relative', ...CENTER }}>
            <Stack direction={'column'} spacing={3} width={isMobile ? '90%' : '70%'} alignItems={'center'} padding={'30px 0'}>
                <Typography variant={isMobile ? "h5" : "h4"} fontWeight={'700'} textAlign={'center'} width={isMobile ? '90%' : '80%'} color={'#000'}>Mengenal Lebih Dekat dengan Para Pengajar Profesional</Typography>
                <Typography variant="h6" textAlign={'center'} color={'#ababab'} fontWeight={'400'}>Mengembangkan diri melalui pengembangan dan pelatihan</Typography>
                <Grid container spacing={isMobile ? 1 : 3}>
                    {
                        ProfessorsData.map((item, index) => (
                            <Grid item md={3} sm={6} xs={6} key={index}>
                                <Card sx={{ width: '100%', height: '100%' }}>
                                    <div style={{ backgroundColor: item.color, width: '100%', height: 100, position: 'relative', ...CENTER }}>
                                        <img
                                            src={Assets.images.profile}
                                            style={{
                                                width: 'auto',
                                                height: '80%',
                                                aspectRatio: 1,
                                                borderRadius: 100,
                                            }} alt=""
                                        />
                                    </div>
                                    <CardContent>
                                        <Stack direction={"column"} alignItems={'center'} spacing={1}>
                                            <Typography textAlign={'center'} variant={"body2"} color="#ababab">{item.NID}</Typography>
                                            <Typography textAlign={'center'} variant={"body1"} color="#000">{item.name}</Typography>
                                            <Typography textAlign={'center'} variant={"body2"} sx={{ padding: 1, border: '1px solid #ababab', borderRadius: 3 }}>{item.position}</Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )).slice(0, isMobile ? 6 : 12)
                    }
                </Grid>
                <div>
                    <Stack
                        onClick={() => setDetail(true)}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        spacing={1}
                        direction={'row'}
                        className={styles.btnPrimary}
                        sx={{ padding: '10px 20px', borderRadius: 3 }}
                    >
                        <Typography variant={'subtitle2'} style={{ color: 'inherit' }}><b>Lihat Selengkapnya</b></Typography>
                        <Icon fontSize={'medium'} sx={{ color: '#fff' }}>open_in_new</Icon>
                    </Stack>
                </div>
            </Stack>
            <Lists isOpen={isDetail} onClose={() => setDetail(false)} />
        </div>
    )
}