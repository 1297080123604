import { Assets } from "../../assets"
import { Colors } from "../../utils/colors"
import { CENTER } from "../../utils/styles"
import { Icon, Stack, Typography } from "@mui/material"
import { isMobile, isTablet } from "react-device-detect"
import styles from '../styles.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Mousewheel, Pagination } from 'swiper/modules';
import { HeaderData } from "../data"

export const Header = () => {
    return (
        <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
            <div style={{ width: '100%', height: '100vh', position: 'absolute', display: 'flex', alignItems: 'center' }}>
                <Swiper
                    direction={isMobile ? 'horizontal' : 'vertical'}
                    slidesPerView={1}
                    spaceBetween={30}
                    mousewheel={!isMobile}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination, Mousewheel, Autoplay]}
                    style={{
                        width: isMobile ? '90%' : '50%', height: isMobile ? '60%' : '50%',
                        backgroundColor: '#ffffff50',
                        border: '1px solid #fff',
                        backdropFilter: 'blur(3px)',
                        borderRadius: 10,
                    }}
                >
                    {
                        HeaderData.map((item, index) => (
                            <SwiperSlide key={index} style={{ width: '100%', height: '100%' }}>
                                <div style={{ height: '100%', width: '100%', ...CENTER }}>
                                    <Stack
                                        spacing={3}
                                        direction={'column'}
                                        sx={{
                                            width: '90%',
                                            height: '90%',
                                        }}
                                        alignItems={'flex-start'}
                                        justifyContent={'center'}
                                    >
                                        <Typography variant={isMobile ? "h5" : "h4"} style={{ color: '#000', fontFamily: 'inherit', width: '90%', padding: isMobile ? '0 10px' : '0 30px' }}>
                                            <b>{item.title}</b>
                                        </Typography>
                                        <Typography variant={'body1'} style={{ color: '#000', fontFamily: 'inherit', width: '90%', padding: isMobile ? '0 10px' : '0 30px' }}>
                                            {item.desc}
                                        </Typography>
                                        <div style={{ padding: isMobile ? '0 10px' : '0 30px' }}>
                                            <Stack
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                spacing={1}
                                                direction={'row'}
                                                className={styles.btnPrimary}
                                                sx={{ padding: '10px 20px', borderRadius: 3 }}
                                            >
                                                <Typography variant={'subtitle2'} style={{ color: 'inherit' }}><b>Jelajahi</b></Typography>
                                            </Stack>
                                        </div>
                                    </Stack>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <img src={Assets.images.headerBg} alt="header" style={{ height: '100vh', width: '100%', objectFit: isMobile || (isMobile && isTablet) ? 'cover' : 'fill', userSelect: 'none' }}></img>
        </div>
    )
}