// https://codepen.io/kristen17/pen/wvPebxy?editors=1100
import React from 'react';
import './App.css';
import PageRoutes from './routes/router';
import moment from 'moment';
import 'moment/locale/id'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Poppins', sans-serif",
    },
  },
});

moment.locale('id')

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <PageRoutes />
      </ThemeProvider>
      <ToastContainer
        position={"top-right"}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme={"colored"}
      />
    </div>
  );
}

export default App;
